.ToolBarContainer {
    padding: 1rem 0 1rem 1rem;
}

.ToolButton {
    text-transform: none !important;
    color: rgba(0,0,0,0.72) !important;
}

.ToolButton a {
    color: inherit;
    text-decoration: inherit;
}
